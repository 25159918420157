import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { useSearchContext } from '../../SearchContext';
import clicked_building_marker from "../../images/map_markers/clicked_building_marker.svg";
import SearchBox from './searchBox/searchBox';
import { useMediaQuery, useTheme } from '@mui/material';
import { ZoomControl, FullscreenControl, MapTypeControl } from "./customMapControls";
import { ReactComponent as LoaderButton } from '../../images/logos/logo-house.svg';
import { Box } from '@mui/material';

const countryCoordinates = {
    norway: { lat: 59.9139, lng: 10.7522 },
    uk: { lat: 51.864445, lng: -2.244444 },
};

const HomeMap = ({ google, setIsAuthenticated }) => {
    const { language, searchCountry, setSelectedIndex, setSearchLong, setSearchLat, propertyData, allBuilding, selectedBuilding, setSearchByAddress } = useSearchContext();
    const [mapKey, setMapKey] = useState(0);
    const [selectedMarker, setSelectedMarker] = useState([])
    const [mapType, setMapType] = useState('roadmap')
    const isSmallerScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const [mapInstance, setMapInstance] = useState(null);
    const [mapTypeText, setMapTypeText] = useState('Satellite');

    const savedLat = localStorage.getItem('previousSearchlat');
    const savedLng = localStorage.getItem('previousSearchlng');
    const prevSearchCoords = {
        lat: savedLat,
        lng: savedLng,
    }

    useEffect(() => {
        // set the map key to a new value to force the map to re-render with new data.
        setMapKey(mapKey + 1);
        setSelectedIndex('all_adaptations')
        setSelectedMarker([])
    }, [propertyData]);

    useEffect(() => {
        if (mapInstance && google) {
            const newCenter =
                prevSearchCoords.lat == null
                    ? countryCoordinates[searchCountry]
                    : { lat: prevSearchCoords.lat, lng: prevSearchCoords.lng };

            if (newCenter) {
                mapInstance.panTo(newCenter);
            }
        }
    }, [searchCountry, language, selectedBuilding]);

    function getCoordinates(props, marker, e) {
        const lat = e.latLng.lat()
        const lon = e.latLng.lng()
        setSearchLat(lat)
        setSearchLong(lon)
        setSearchByAddress(false);
        setSelectedMarker([{ 'lat': lat, 'lon': lon }])
    }

    const addMapTypeListener = (mapProps, map) => {
        const listener = () => {
        setMapType(map.getMapTypeId());
        };

        map.addListener('maptypeid_changed', listener);

        // Cleanup listener on unmount
        return () => {
        google.maps.event.removeListener(listener);
        };
    };

        const onMapReady = (mapProps, map) => {
            setMapInstance(map);
            map.setOptions({
            mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
            });
            addMapTypeListener(mapProps, map);
        };

        useEffect(() => {
        const updateMarkers = (markersData, markerType, iconGetter, clickHandler) => {
            if (mapInstance && google && markersData.length > 0) {
            // Clear existing markers
            if (mapInstance[markerType]) {
                mapInstance[markerType].forEach(marker => marker.map = null);
            }
            mapInstance[markerType] = [];
        
            // Create and add new markers
            markersData.forEach((data) => {
                const iconElement = document.createElement('img');
                iconElement.src = iconGetter(data)
                iconElement.style.width = '35px';
                iconElement.style.height = '35px';

                // Add data-testid or other test attribute for Cypress
                iconElement.setAttribute('data-testid', `building-marker-${data.building_id}`);

                const marker = new google.maps.marker.AdvancedMarkerElement({
                position: {
                    lat: data.lat,
                    lng: data.lon,
                },
                map: mapInstance,
                content: iconElement, // Use content instead of icon
                });
        
                // Add click event to marker if a click handler is provided
                if (clickHandler) {
                marker.addListener('click', () => clickHandler(data));
                }
        
                // Store marker reference for cleanup
                mapInstance[markerType].push(marker);
            });
            }
        };
        
        // Update selected markers
        updateMarkers(selectedMarker, 'advancedMarkers', () => clicked_building_marker, null);
        
        }, [selectedMarker, allBuilding, mapInstance, google, selectedBuilding]);    

    return(
        // if no building is selected return inital map with a zoomed out view of Oslo
        <>
            <Map
            google={google}
            zoom={prevSearchCoords.lat==null ? 12 : 17}
            initialCenter={
            prevSearchCoords.lat == null
                ? countryCoordinates[searchCountry] || { lat: 0, lng: 0 }
                : { lat: prevSearchCoords.lat, lng: prevSearchCoords.lng }
        }
        
            cursor={'pointer'}
            onClick={getCoordinates}
            mapTypeControl={false}
            fullscreenControl={false}
            zoomControl={false}
            streetViewControl={false}
            onReady={onMapReady}
        >

        <SearchBox setIsAuthenticated={setIsAuthenticated}/>

        {/* custom styled map control buttons */}
        <ZoomControl map={mapInstance} isSmallerScreen={isSmallerScreen}/>
        <MapTypeControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} mapTypeText={mapTypeText} setMapTypeText={setMapTypeText}/>
        <FullscreenControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} />

        </Map>
        </>
    )

};

const LoadingContainer = () => (
    <Box
    sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        width: '100%',
    }}
    >
    <LoaderButton className="logo-loader" />
    </Box>
)

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_TOKEN,
    LoadingContainer: LoadingContainer,
    libraries: ['marker']
})(HomeMap);