import React from 'react';
import { Box, Tab, TextField, FormControl, Typography, Button } from '@mui/material';
import { CustomTabs } from '../../customStyledComponents';
import { SearchResults } from './SearchResults';

const SearchBySelector = ({
    searchByAddress,
    setSearchByAddress,
    address,
    setAddress,
    searchLat,
    setSearchLat,
    searchLong,
    setSearchLong,
    results,
    setResults,
    setCountryMenuOpen
    }) => {

    const handleTabChange = (event, newValue) => {
        setSearchByAddress(newValue === 0);
    };

    return (
        <>
        <Box sx={{ display: 'flex', justifyContent:'space-between' }}>
            <Typography sx={{ color:'#6A6C6F', fontSize: '10px' }}>Search by:</Typography>
            <Button sx={{ padding: 0, fontSize: '10px' }} onClick={() => setCountryMenuOpen(true)}>Change country</Button>
        </Box>
        <CustomTabs fontSize="14px" value={searchByAddress ? 0 : 1} onChange={handleTabChange}>
            <Tab label="Address" />
            <Tab label="Coordinates" />
        </CustomTabs>
        <Box sx={{ paddingBottom: 2, paddingTop: 1 }}>
            <FormControl sx={{ width: '100%' }}>
            {searchByAddress ? (
                <TextField
                fullWidth
                id="address"
                placeholder="Enter address"
                variant="outlined"
                sx={{ justifyContent: 'flex-start' }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                />
            ) : (
                <>
                <TextField
                    fullWidth
                    id="latitude"
                    placeholder="Enter latitude"
                    variant="outlined"
                    sx={{ justifyContent: 'flex-start', mb: 2 }}
                    value={searchLat}
                    onChange={(e) => setSearchLat(e.target.value)}
                />
                <TextField
                    fullWidth
                    id="longitude"
                    placeholder="Enter longitude"
                    variant="outlined"
                    sx={{ justifyContent: 'flex-start' }}
                    value={searchLong}
                    onChange={(e) => setSearchLong(e.target.value)}
                />
                </>
            )}
            {address !== '' && searchByAddress && (
                <SearchResults
                results={results}
                setResults={setResults}
                address={address}
                setAddress={setAddress}
                searchByAddress={searchByAddress}
                />
            )}
            </FormControl>
        </Box>
        </>
    );
};

export default SearchBySelector;