import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Cityscape from "../images/logos/cityscape.svg";

const UnderMaintenance = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box sx={{
            backgroundColor: '#F8F6F4',
            height: 'calc(100vh - 48px)',  // Adjusted for margin
            margin: 3,
            display: 'flex',
            borderRadius: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                alignItems: 'center',
                justifyContent: 'center',
                height: {
                    xs: '20vh',
                    sm: '35vh',
                    md: '40vh',
                    lg: '50vh',
                },
                paddingBottom: {
                    xs: '10vh',
                    sm: '20vh',
                    md: '40vh',
                    lg: '50vh',
                },
                px: {
                    xs: 2,
                    sm: 4,
                    md: 10,
                    lg: 35,
                    xl: 40
                }
            }}>
                <Typography 
                    sx={{ 
                        color: '#4E4742', 
                        fontSize: isSmallScreen ? '18px' : '25px', 
                        fontWeight: 400, 
                        lineHeight: isSmallScreen ? '22px' : '27.5px', 
                        textAlign: 'center' 
                    }}
                >
                    Hi there, we are temporarily down for maintenance.
                </Typography>
                <Typography
                    color='primary' 
                    sx={{ 
                        fontSize: isSmallScreen ? '50px' : '90px', 
                        fontWeight: 500, 
                        lineHeight: isSmallScreen ? '60px' : '81px', 
                        textAlign: 'center' 
                    }}
                >
                    We’re working hard to keep everything above water. 
                </Typography>
            </Box>

            <Box component="img" src={Cityscape} alt="Cityscape" 
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderRadius: '0 0 20px 20px' // Matches box's border radius at the bottom
                }}
            />
        </Box>
    );
}

export default UnderMaintenance;


