import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Icon1 from '../images/login_icons/login_icon_1.svg';
import Icon2 from '../images/login_icons/login_icon_2.svg';
import Icon3 from '../images/login_icons/login_icon_3.svg';

const UnderConstruction = ({pageName}) => {

    return (
          <Box
          component="main"
          sx={{
                backgroundColor: '#FFFFFF',
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                mt: 1,
                gridAutoFlow: 'row',
          }}
          > 
            <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
                <Grid container spacing={1}>
                <Grid item s={12} lg={12}>
                    <Paper
                    sx={{mt: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        backgroundColor: '#F8F6F4',
                    }}
                    >
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="h2" color="primary" sx={{mb: 4}}>Curently under construction</Typography>
                    <Typography variant="bodyRegular" sx={{my: 4}}>{pageName} service coming soon</Typography>
                    </Box>
                    <Box
                    sx={{
                        display: 'flex',
                        width: '80%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        my: 4,
                    }}>
                        <img src={Icon1} width={50} height={50} alt='Icon1'/>
                        <img src={Icon2} width={50} height={50} alt='Icon2'/>
                        <img src={Icon3} width={50} height={50} alt='Icon3'/>
                    </Box>
                    </Paper>
                </Grid>
                </Grid>
            </Container>
        </Box>
    );
    }

export default UnderConstruction;