import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
    useMediaQuery,
    Divider,
} from '@mui/material';
import { Add, Close, Remove } from '@mui/icons-material';
import i18next from 'i18next';
import pinkDot from '../../../images/icons/pink_dot.svg';
import { CustomAccordion } from '../../customStyledComponents';

const Datasheet = ({ solution, open, handleCloseDialog, datasheetsData}) => {
    const { t } = useTranslation();
    const solutionData = datasheetsData[solution.solution];
    const userLang = i18next.language;
    const moreCobenefits = solutionData.more_cobenefits;
    const [expanded, setExpanded] = useState(false);
    let solutionImage;
    try {
        solutionImage = require(`../../../images/solutions/${solution.solution}.jpg`);
    } catch (e) {
        solutionImage = require(`../../../images/solutions/image_coming_soon.jpg`); // Fallback if no image
    }
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:1320px)');
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <Typography key={index} sx={{ mb: 2 }}>
                {line}
            </Typography>
        ));
    };

    const renderCostAndMaintenanceText = (text) => {
        const lines = text.split('\n');
        const complexity = lines[0] ? lines[0] : "";
        const cost = lines[1] ? lines[1] : "";
    
        return (
            <Box sx={{ mt: 2 }}>
                <Box sx={{ display:'flex', flexDirection:'column' }}>
                    <Typography sx={{color:'#A8ADB4', fontSize: '14x', fontWeight: 600, lineHeight: '14px'}}>{t('adaptions_page.complexity')}</Typography>
                    <Typography sx={{ mt: 1, mb: 3, fontSize: '14px', fontWeight: 400, lineHeight: '16.8px' }}>{complexity}</Typography>
                </Box>
                <Box sx={{ display:'flex', flexDirection:'column' }}>
                    <Typography sx={{color:'#A8ADB4', fontSize: '14px', fontWeight: 600, lineHeight:'14px'}}>{t('property_form.cost')}</Typography>
                    <Typography sx={{ mt: 1, mb: 3, fontSize: '14px', fontWeight: 400, lineHeight: '16.8px' }}>{cost}</Typography>
                </Box>
            </Box>
        );
    };

    const mapEffectivenessToWords = (efficiencyCategory) => {
        let word, color;

        switch(efficiencyCategory) {
            case "low":
                word = t('effectiveness.moderate');
                color = '#B331C833';
                break;
            case "medium":
                word = t('effectiveness.effective');
                color = '#B331C880';
                break;
            case "high":
                word = t('effectiveness.super');
                color = '#B331C8';
                break;
            default:
                word = t('effectiveness.unknown');
                color = '#000000';
                break;
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    padding: '0px 6px',
                    whiteSpace: 'nowrap',
                    maxWidth: 'fit-content',
                }}
                >
                <Typography
                    sx={{
                    color: color,
                    fontSize: '30px',
                    lineHeight: '1',
                    marginRight: '4px',
                    position: 'relative',
                    top: '-1.5px',
                    }}
                >
                    •
                </Typography>
                <Typography variant="riskTag">{word}</Typography>
            </Box>
        );
    };

    const renderMoreCobenefits = (benefits) => {
        const benefitKeys = Object.keys(benefits);
    
        return benefitKeys.map((key, index) => {
            const benefit = benefits[key];

            return (
                <CustomAccordion
                    key={key}
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                    index={index}
                    array={benefitKeys}
                >
                    <AccordionSummary expandIcon={expanded === key ? <Remove color='primary' /> : <Add color='primary' />}>
                        <Typography variant="h5" sx={{ margin: 0 }}>{benefit.title[userLang]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {Object.keys(benefit).map((subKey) => {
                            if (subKey === 'title' || subKey === 'img_src') return null;
                            const subBenefit = benefit[subKey];
                            return (
                                <Box key={subKey} sx={{ display: 'flex', flexDirection: 'column', my: 1, ml: 1 }}>
                                    <Typography sx={{ mb: 2, fontSize: '16px', fontWeight: 400, lineHeight: '19.2px' }}>
                                        {subBenefit.title[userLang]}
                                    </Typography>
                                    <Typography sx={{ color:'#747474', fontSize: '16px', fontWeight: 400, lineHeight: '19.2px' }}>
                                        {subBenefit.description[userLang]}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        {mapEffectivenessToWords(subBenefit.impact)}
                                    </Box>
                                </Box>
                            );
                        })}
                    </AccordionDetails>
                </CustomAccordion>
            );
        });
    };

    return (
        <Dialog
        open={open}
        onClose={handleCloseDialog}
        sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            },
        }}
        PaperProps={{
            sx: {
                height: '100vh',
                minHeight: '100vh',
                minWidth: '50vw',
                margin: 0,
                right: 0,
                position: 'fixed',
                borderRadius: '0px',
            }
        }}
        >
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ backgroundColor: '#F8F6F4', px: isMobileScreen ? 0 : 5, py: 5 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 5}}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={pinkDot} height={10} width={10} alt="Pink Dot" />
                            <Typography sx={{ ml: 1 }}>{t('adaptions_page.mitigrate_solutions')}</Typography>
                        </Box>
                        <IconButton onClick={handleCloseDialog}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={{ px: 5, my: 6 }}>
                        <Typography variant="h3" sx={{ mb: 2 }}>{solutionData.title[userLang]}</Typography>
                        <Typography sx={{color: '#747474', fontSize: '14px', fontWeight: 400, lineHeight: '19.2px'}}>{solutionData.description[userLang]}</Typography>
                    </Box>
                    {isSmallScreen ? (
                        <Box sx={{ px: 5 }}>
                            {['hazard', 'location', 'function', 'property_value', 'green_finance'].map((field, index) => (
                                <Box key={field} sx={{ mb: 4, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                                    <Typography sx={{color:'#A8ADB4', fontSize: '10px', fontWeight: 600}}>{solutionData.top_banner[field].title[userLang]}</Typography>
                                    <Typography variant="subtitle1">{solutionData.top_banner[field].description[userLang]}</Typography>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box sx={{ px: 3 }}>
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: 0, tableLayout: 'fixed', width: '100%' }}>
                                <TableHead>
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        {['hazard', 'location', 'function', 'property_value', 'green_finance'].map((field) => (
                                            <TableCell key={field} sx={{ borderBottom: 'none', py: 0.5,  }}>
                                                <Typography sx={{color:'#A8ADB4', fontSize: '10px', fontWeight: 600}} noWrap>
                                                    {solutionData.top_banner[field].title[userLang]}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        {['hazard', 'location', 'function', 'property_value', 'green_finance'].map((field) => (
                                            <TableCell key={field} sx={{ borderBottom: 'none', pt: 0.5,  }}>
                                                <Typography variant="subtitle1">{solutionData.top_banner[field].description[userLang]}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    )}

                    <Box sx={{px: 5, mb: 4, mt:6}}>
                        <img src={solutionImage} alt="Solution" style={{ width: '100%', borderRadius: '10px' }} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isMobileScreen ? 'column' : 'row', justifyContent: 'space-between', px: 5}}>
                    <Box sx={{ px: isMobileScreen ? 0 : 5, my: 5, width: isMobileScreen ? '100%' : '55%' }}>
                        <Typography variant="h3" sx={{ mb: 3 }}>{solutionData.installation_and_maintenance.title[userLang]}</Typography>
                        <Typography sx={{ color:'#747474', fontSize: '16px', fontWeight: 400, lineHeight: '19.2px' }}>{renderTextWithLineBreaks(solutionData.installation_and_maintenance.description[userLang])}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: isMobileScreen ? 'row' : 'column', justifyContent: 'flex-start', alignItems: 'flex-start', px: isMobileScreen ? 0 : 5, my: 5, width: isMobileScreen ? '100%' : '30%' }}>
                        <Box sx={{ mb: 3, mr: isMobileScreen ? 5 : 0 }}>
                            <Typography sx={{fontSize: '12px', fontWeight: 600, lineHeight: '12px', mb: 1}} color='primary'>{solutionData.installation_and_maintenance.image_text.maintenance.title[userLang]}</Typography>
                            {renderCostAndMaintenanceText(solutionData.installation_and_maintenance.image_text.maintenance.description[userLang])}
                        </Box>
                        <Box>
                            <Typography sx={{fontSize: '12px', fontWeight: 600, lineHeight: '12px', mb: 1}} color='primary'>{solutionData.installation_and_maintenance.image_text.installation.title[userLang]}</Typography>
                            {renderCostAndMaintenanceText(solutionData.installation_and_maintenance.image_text.installation.description[userLang])}
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ mb: 8, mx: 5 }}/>
                <Box sx={{ display: 'flex', flexDirection: isMobileScreen ? 'column' : 'row', justifyContent: 'space-between', px:5 }}>
                    <Box sx={{ px: isMobileScreen ? 0 : 5, width: isMobileScreen ? '100%' : '55%' }}>
                        <Typography variant="h3" sx={{ mb: 3 }}>{t('main_page.co_benefits')}</Typography>
                        <Typography sx={{ color:'#747474', fontSize: '16px', fontWeight: 400, lineHeight: '19.2px' }}>{renderTextWithLineBreaks(solutionData.co_benefits.description[userLang])}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: isMobileScreen ? 0 : 5, width: isMobileScreen ? '100%' : '30%' }}>
                        <Box>
                            <Typography sx={{fontSize: '12px', fontWeight: 600, lineHeight: '12px'}} color='primary'>{solutionData.top_banner.effeciency.title[userLang]}</Typography>
                            <Typography sx={{ mt: 3 }}>{mapEffectivenessToWords(solution.efficiency_category)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ px: isMobileScreen ? 5 : 10, my: 5 }}>
                        {renderMoreCobenefits(moreCobenefits)}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

Datasheet.propTypes = {
    solution: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default Datasheet;
