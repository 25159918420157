import React from 'react';
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material';
import ExistingMeasuresTable from './existingMeasuresTable';
import SelectMeasures from './selectMeasures';

const ExistingMeasures = ({ existingMeasures, setExistingMeasures, buildingId, setAddMeasure, setEditMeasure, setMeasureToEdit, riskSolutions, selectedMeasures, setSelectedMeasures, isModalOpen, setIsModalOpen, newMeasures, setNewMeasures }) => {
    const { t } = useTranslation();

    const handleAddMeasure = () => {
        setIsModalOpen(true);
        setAddMeasure(true);
        setSelectedMeasures([]);
        setNewMeasures([]);
    }

    return (
        <Box sx={{ border: '0.5px solid #E9EBEE', borderBottom: 'none', borderRadius: '5px' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                    mx: 2, 
                    mb: 1,
                    mt: 1
                }}
            >
                <Box>
                    <Typography variant="h3" gutterBottom>
                        {t('adaptions_page.existing_measures_overview')}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#A8ADB4', lineHeight: '15.4px' }} gutterBottom>
                        {t('adaptions_page.view_and_report_existing_measures')}. {t('adaptions_page.measures_only_visible_to_your_organisation')} 
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: 'primary',
                        color: 'white',
                        ':hover': {
                            backgroundColor: '#B52C4C',
                        },
                        display: 'flex',
                        height: '46px',
                        boxShadow: 'none',
                        borderRadius: '3px',
                        padding: '10px 12px',
                        lineHeight: '15.4px',
                        width: 'auto',
                        ml: { xs: 0, sm: 2 },
                        mt: { xs: 0, sm: 1 }
                    }}
                    onClick={handleAddMeasure}
                >
                    {t('adaptions_page.report_measure')}
                </Button>
            </Box>
            <ExistingMeasuresTable
                existingMeasures={existingMeasures}
                setExistingMeasures={setExistingMeasures}
                buildingId={buildingId}
                setEditMeasure={setEditMeasure}
                setMeasureToEdit={setMeasureToEdit}
                riskSolutions={riskSolutions}
                newMeasures={newMeasures}
            />
            {isModalOpen && (
                <SelectMeasures
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    existingMeasures={existingMeasures}
                    riskSolutions={riskSolutions}
                    selectedMeasures={selectedMeasures}
                    setSelectedMeasures={setSelectedMeasures}
                    setAddMeasure={setAddMeasure}
                />
            )}
        </Box>
    );
    
}

export default ExistingMeasures;