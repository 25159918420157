import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Paper, Button, Alert,
  Accordion, AccordionSummary, AccordionDetails,
  useMediaQuery, useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../../../SearchContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoaderButton } from '../../../images/loaders/loader_white.svg';
import PropertyTickBoxes from './propertyTickBoxes';
import useTokenValidation from '../../../useTokenValidation';
import SearchBySelector from './searchBySelector';

const SearchBox = ({setIsAuthenticated}) => {
    const { setCountryMenuOpen, searchCountry, returnPeriod, riskYear, setSelectedBuilding, searchLat, setSearchLat, searchLong, setSearchLong, isLoading, setIsLoading, setPropertyData, setHasLoadedData, addressNotFound, setAddressNotFound, searchByAddress, setSearchByAddress, hasBasement, setHasBasement, isGroundFloor, setIsGroundFloor, experiencedSewageBackup, setExperiencedSewageBackup } = useSearchContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [results, setResults] = useState([]);
    const token = localStorage.getItem('token');
    const [address, setAddress] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        hasBasement: false,
        isGroundFloor: false,
        experiencedSewageBackup: false,
    });
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isBelowMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    useTokenValidation(setIsAuthenticated);

    const resetTickBoxes = () => {
      setHasBasement({
          yes: false,
          no: false,
          idk: false,
      });
      setIsGroundFloor({
          yes: false,
          no: false,
          idk: false,
      });
      setExperiencedSewageBackup({
          yes: false,
          no: false,
          idk: false,
      });
  };

    useEffect(() => {
      resetTickBoxes();
  }, []);

  const get_data = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setAddressNotFound(false);
    setResults([]);
    setIsLoading(prevState => ({ ...prevState, data: true }));

    // Validate checkboxes
    const errors = {
        hasBasement: !(hasBasement.yes || hasBasement.no || hasBasement.idk),
        isGroundFloor: !(isGroundFloor.yes || isGroundFloor.no || isGroundFloor.idk),
        experiencedSewageBackup: !(experiencedSewageBackup.yes || experiencedSewageBackup.no || experiencedSewageBackup.idk),
    };

    setValidationErrors(errors);

    if (errors.hasBasement || errors.isGroundFloor || errors.experiencedSewageBackup) {
        setIsLoading(prevState => ({ ...prevState, data: false }));
        return;
    }

    try {
        let latitude = searchLat;
        let longitude = searchLong;

        // If searching by address, fetch coordinates
        if (searchByAddress && !latitude && !longitude) {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-coords/${address}/${searchCountry}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const lat_long = response.data;
            latitude = lat_long.lat;
            longitude = lat_long.long;

            setSearchLat(latitude);
            setSearchLong(longitude);

            // Log the search with the address
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/log/searches/${address}`, {}, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });
        } else {
          // Log the search with the coordinates
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/log/searches/${latitude},${longitude}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          });
        }

        // Fetch building information
        const buildingInfoResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/coordinates/filtered/${latitude},${longitude}/${searchCountry}/${returnPeriod}/${riskYear}/?has_basement=${!hasBasement.no}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const buildings_info = buildingInfoResponse.data;
        setResultsState(buildings_info);

    } catch (error) {
        errorHandler(error);
    } finally {
        setIsLoading(prevState => ({ ...prevState, data: false }));
    }
};



    const errorHandler = (error) => {
        console.log(error);
        if (error.response) {
            if (error.response.status === 429) {
                alert('You have exceeded your request limit. Please contact the administrator.');
            } else if (error.response.status === 404) {
                setAddressNotFound(true);
                navigate(`/prevent`);
            } else if (error.response.status === 504) {
                alert('Server timeout. Please try again later.');
            }
        } else {
            alert('Something went wrong. Please try again later.');
        }
        setIsLoading(false);
        setAddress("");
        resetTickBoxes();
        setSearchLat("");
        setSearchLong("");
    };

    const setResultsState = (buildings_info) => {
        setPropertyData(buildings_info);
        setSelectedBuilding(buildings_info['building_id']);
        setHasLoadedData(true);
        setSearchByAddress(true);
        const coords = {
          lat: buildings_info.lat,
          lng: buildings_info.lon,
        };
        localStorage.setItem('previousSearchlat', JSON.stringify(coords.lat));
        localStorage.setItem('previousSearchlng', JSON.stringify(coords.lng));
        navigate(`adaptions/${buildings_info['building_id']}?risk_year=${riskYear}&return_period=${returnPeriod}&has_basement=${Object.keys(hasBasement).find(key => hasBasement[key])}&is_ground_floor=${Object.keys(isGroundFloor).find(key => isGroundFloor[key])}&experienced_sewage_backup=${Object.keys(experiencedSewageBackup).find(key => experiencedSewageBackup[key])}`);
    };

    const handleTabChange = (event, newValue) => {
      setSearchByAddress(newValue === 0); // 0 for "Search by Address", 1 for "Search by Coordinates"
    };

    const alertBox = (
      <Box>
        <Alert 
            icon={false}
            onClose={() => setAddressNotFound(false)}
            sx={{
                backgroundColor: '#fff',
                padding: isSmallerScreen ? 0 : 2,
                fontSize: isSmallerScreen ? '10px' : '14px',
                color: '#D61616'
            }}
        >
        No properties found with these details in this country.
        If you think it should be in the database, please contact <strong>webapp-support@mitigrate.com.</strong>
        </Alert>
    </Box>
    )

    return (
      <Box
        sx={{
          display: 'inline-flex',
          padding: { xs: 0, sm: 2, lg: 3 },
          alignItems: 'flex-start',
          justifyContent: 'center',
          position: { xs: 'fixed', md: 'relative' },
          bottom: { xs: 0, md: 'auto' },
          left: { xs: '50%', md: 'auto' },
          transform: { xs: 'translateX(-50%)', md: 'none' },
          width: { xs: '100%', md: 'auto' },
          zIndex: 1000,
        }}
      >
        {isSmallerScreen ? (
          <Accordion
            expanded={accordionExpanded}
            onChange={() => setAccordionExpanded(!accordionExpanded)}
            sx={{
              width: '90vw',
              '& .MuiAccordionSummary-root': {
                minHeight: '0px',
                '&.Mui-expanded': {
                  minHeight: '0px',
                },
              },
              '& .MuiAccordionSummary-content': {
                margin: '8px 0px 8px 0px',
              },
              '& .MuiAccordionDetails-root': {
                padding: '8px 16px',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3">{t('main_page.search_for_property')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper component="form" onSubmit={get_data} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mb: 0.5, color: '#8A8A8A' }}>
                  {t('main_page.enter_address_or_click_map')}
                </Typography>
                <SearchBySelector
                  searchByAddress={searchByAddress}
                  setSearchByAddress={setSearchByAddress}
                  address={address}
                  setAddress={setAddress}
                  searchLat={searchLat}
                  setSearchLat={setSearchLat}
                  searchLong={searchLong}
                  setSearchLong={setSearchLong}
                  results={results}
                  setResults={setResults}
                  setCountryMenuOpen={setCountryMenuOpen}
                />
                <PropertyTickBoxes validationErrors={validationErrors} />
                {addressNotFound && alertBox}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  {!isLoading.data ? t('main_page.search') : <LoaderButton className="spinner" />}
                </Button>
              </Paper>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Paper
            component="form"
            onSubmit={get_data}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 3,
              width: { xs: '90vw', md: '40vw', lg: '30vw' },
            }}
          >
            <Typography variant="h4" sx={{ mb: 1 }}>
              {t('main_page.search_for_property')}
            </Typography>
            <Typography variant="bodyRegular" sx={{ mb: 1, color: '#8A8A8A' }}>
              {t('main_page.enter_address_or_click_map')}
            </Typography>
            <SearchBySelector
              searchByAddress={searchByAddress}
              setSearchByAddress={setSearchByAddress}
              address={address}
              setAddress={setAddress}
              searchLat={searchLat}
              setSearchLat={setSearchLat}
              searchLong={searchLong}
              setSearchLong={setSearchLong}
              results={results}
              setResults={setResults}
              setCountryMenuOpen={setCountryMenuOpen}
            />
            <PropertyTickBoxes validationErrors={validationErrors} />
            {(addressNotFound && isBelowMediumScreen) && alertBox}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {!isLoading.data ? t('main_page.search') : <LoaderButton className="spinner" />}
            </Button>
          </Paper>
        )}
      </Box>
    );
}

export default SearchBox;
