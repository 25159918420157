import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    TableCell,
    TableRow,
    Collapse,
    IconButton,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InfoIcon from '../../../images/icons/info_icon.svg';
import InfoIconHover from '../../../images/icons/info_icon_hover.svg';
import { useTranslation } from 'react-i18next';
import { formatCobenefits, formatAccuracy } from '../formattingUtils';

const AdaptionTableRow = ({ row, index, expandedRow, handleRowClick, handleOpenDatasheet, mapEffectivenessToWords, formatRisks }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 0 }, border: 0 }}>
            <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: 0, borderBottom: 0, display: 'flex', alignItems: 'center' }}>
                <IconButton 
                    sx={{ 
                    ml: -1.5, 
                    mr: -1,         
                    color: expandedRow === index ? theme.palette.primary.main : '#A8ADB4',
                    '&:hover': {
                        color: theme.palette.primary.main,
                    }, 
                    }} 
                    onClick={() => handleRowClick(index)}>
                    {expandedRow === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
                <Typography variant='bodyRegular' sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    {t(`solution.${row.solution}.name`)}
                    <img 
                    src={isHovered ? InfoIconHover : InfoIcon} 
                    alt="info-icon" 
                    style={{ 
                        cursor: 'pointer', 
                        marginLeft: 3, 
                        width: '16px', 
                        height: '16px', 
                        verticalAlign: 'middle',
                    }} 
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => handleOpenDatasheet(row)} 
                    />
                </Typography>
                </TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{mapEffectivenessToWords(row.efficiency_category)}</TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{formatRisks(row.risks)}</TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{formatCobenefits(row.cobenefits)}</TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{Math.round(row.size)} {t(`pdf.unit_translation.${row.unit_r}`)}</TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{row.cost_range}</TableCell>
                <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>
                    <Box sx={{ display: 'inline-flex', alignItems: 'center', borderRadius: '3px', border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)', padding: '2px 8px', margin: '2px', whiteSpace: 'nowrap', maxWidth: 'fit-content' }}>
                        {formatAccuracy(row.accuracy)}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: '0.5px solid #E9EBEE' }} colSpan={9}>
                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                        <Typography variant="body2" style={{ padding: '16px' }}>
                            {t(`solution.${row.solution}.long_description`)}
                        </Typography>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

AdaptionTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    expandedRow: PropTypes.number,
    handleRowClick: PropTypes.func.isRequired,
    handleOpenDatasheet: PropTypes.func.isRequired,
    mapEffectivenessToWords: PropTypes.func.isRequired,
    formatRisks: PropTypes.func.isRequired
};

export default AdaptionTableRow;
