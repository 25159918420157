import React from 'react';
import { Box, Tab } from '@mui/material';
import { CustomTabs } from '../../customStyledComponents';

const AdaptionTableTabs = ({ t, tabValue, handleTabChange }) => {

    return (
        <Box sx={{margin: 1}}>
            <CustomTabs
                value={tabValue}
                onChange={handleTabChange}>
                <Tab label={t('adaptions_page.most_effective')} />
                <Tab label={t('adaptions_page.most_affordable')} />
                <Tab label={t('adaptions_page.greenest')} />
            </CustomTabs>
        </Box>
    );
}

export default AdaptionTableTabs;