import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../images/icons/info_icon.svg';
import InfoIconHover from '../../images/icons/info_icon_hover.svg';
import { getRiskLevelText, getRiskColor } from './formattingUtils';
import { Divider } from '@mui/material';

const riskLevelOrder = {
    "very high": 4,
    "high": 3,
    "medium": 2,
    "low": 1,
    "no risk": 0
};

const RiskLevels = ({ riskLevels }) => {
    const { t } = useTranslation();
    const [anchorEls, setAnchorEls] = useState({});
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const infoIconRefs = useRef([]);

    const handlePopoverOpen = (event, index) => {
        setAnchorEls((prev) => ({ ...prev, [index]: infoIconRefs.current[index] }));
    };

    const handlePopoverClose = (index) => {
        setAnchorEls((prev) => ({ ...prev, [index]: null }));
    };

    const handleMouseEnter = (index) => {
        setHoveredIcon(index);
    };

    const handleMouseLeave = () => {
        setHoveredIcon(null);
    };

    const riskAttributes = [
        { key: "basement", label: "main_page.basement" },
        { key: "obstruction", label: "main_page.obstruction" },
        { key: "inundation", label: "main_page.inundation" },
        { key: "runoff", label: "main_page.runoff" }
    ];

    const riskItems = riskAttributes.map(({ key, label }) => {
        const riskValue = riskLevels[key];
        return {
            risk: key,
            label: t(label),
            text: getRiskLevelText(riskValue, t),
            color: getRiskColor(riskValue),
            value: riskValue
        };
    });

    const sortedRiskItems = riskItems.sort((a, b) => riskLevelOrder[b.value] - riskLevelOrder[a.value]);

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                border: '0.5px solid #E9EBEE',
                mt: 2,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>{t("pdf.contents_page.current_risk")}</Typography>
            </Box>
            {sortedRiskItems.map((risk, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Box sx={{ display: 'inline-flex' }}>
                        <Typography variant="bodyRegular" sx={{color:'#6A6C6F'}}>{risk.label}</Typography>
                        <img
                            src={hoveredIcon === index ? InfoIconHover : InfoIcon} // Switch icon on hover
                            alt="info-icon"
                            ref={(el) => (infoIconRefs.current[index] = el)}
                            style={{ cursor: 'pointer', marginLeft: '4px', width: '16px', height: '16px' }}
                            onMouseEnter={() => {
                                handlePopoverOpen(null, index);
                                handleMouseEnter(index);
                            }}
                            onMouseLeave={() => {
                                handlePopoverClose(index);
                                handleMouseLeave();
                            }}
                        />
                        <Popper
                            open={Boolean(anchorEls[index])}
                            anchorEl={anchorEls[index]}
                            placement="top"
                            disablePortal
                            style={{ zIndex: 1300 }}
                        >
                            <Paper
                                sx={{
                                    padding: 1,
                                    maxWidth: 300,
                                    wordWrap: 'break-word',
                                    boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)',
                                    lineHeight: '13.2px',
                                }}
                            >
                                <Typography variant="bodyTiny">{t(`risks.${risk.risk}.description`)}</Typography>
                            </Paper>
                        </Popper>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="bodyRegular">{risk.text}</Typography>
                        <Typography sx={{ color: risk.color, ml: 0.5, fontSize: '20px', fontWeight: 800 }}>•</Typography>
                    </Box>
                </Box>
            ))}
            <Divider sx={{ borderBottomWidth: 0.5, mt: 1.5 }}/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                <Typography variant="bodyRegular" sx={{color:'#6A6C6F'}}>{t("main_page.river_flooding")}</Typography>
                <Typography color='primary'variant="bodyRegular">{t("main_page.coming_soon")}</Typography>
            </Box>
        </Paper>
    );
};

RiskLevels.propTypes = {
    riskLevels: PropTypes.shape({
        basement: PropTypes.string.isRequired,
        obstruction: PropTypes.string.isRequired,
        inundation: PropTypes.string.isRequired,
        runoff: PropTypes.string.isRequired
    }).isRequired
};

export default RiskLevels;
