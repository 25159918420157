import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon1 from '../../images/login_icons/login_icon_1.svg';
import Icon2 from '../../images/login_icons/login_icon_2.svg';
import Icon3 from '../../images/login_icons/login_icon_3.svg';
import { useTranslation } from "react-i18next";

const LoginWelcome = () => {
    const { t } = useTranslation();

    return (
        <Box>
        <Box
        sx={{
            px: 16,
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Typography variant="h1" sx={{ color:'#39393D', textAlign: 'center'}}>{ t('login.welcome_to') }</Typography>
            <Typography variant="h1" color="primary" sx={{mb: 7.5, textAlign: 'center'}}>Mitigrate</Typography>
            <Typography variant="bodyLarge" sx={{mx: 5, mb:4, textAlign: 'center'}}>{ t('login.intro') }</Typography>
        </Box>
        <Box
        sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            px: 6,
            mt: 10,
            mb: 6
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2}}>
                <img src={Icon1} width={50} height={50} alt='Icon1'/>
                <Typography sx={{maxWidth: 120, textAlign: 'center'}}>{t('login.icon_1_text')}</Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2}}>
                <img src={Icon2} width={50} height={50} alt='Icon2'/>
                <Typography sx={{maxWidth: 120, textAlign: 'center'}}>{t('login.icon_2_text')}</Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2}}>
                <img src={Icon3} width={50} height={50} alt='Icon3'/>
                <Typography sx={{maxWidth: 120, textAlign: 'center'}}>{t('login.icon_3_text')}</Typography>
            </Box>
        </Box>
        </Box>
    )
}

export default LoginWelcome;