import React, { useState } from "react";
import useTokenValidation from '../../useTokenValidation';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme, Box, Typography, Container, Grid, Paper, Avatar, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangePassword from "./changePassword";
import PersonalInfo from "./personalInfo";
import BreadCrumbs from "../breadCrumbs";

const UserProfile = ({ setIsAuthenticated }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [tabValue, setTabValue] = useState(0);
    const userData = JSON.parse(localStorage.getItem('user_data'));

    useTokenValidation(setIsAuthenticated);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: '#FFFFFF',
                flexGrow: 1,
                height: '100%',
                overflow: 'auto',
                mt: 8
            }}
        >
            <Box sx={{ mt: 1, mb: 1 }}>
                <BreadCrumbs pageNames={[{ name: 'User', route: '/user' }]} />
            </Box>
            <Box sx={{ display: 'block' }}>
                <Container maxWidth="100%">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={4} sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>

                            <Paper sx={{ backgroundColor: '#F8F6F4' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 5 }}>
                                    <Avatar sx={{ width: 94, height: 94, mb: 6 }}>{userData.first_name[0].toUpperCase()}</Avatar>
                                    <Typography variant="h2" sx={{ mt: 2, mb: 2 }}>{userData.first_name} {userData.last_name}</Typography>
                                    <Typography sx={{ mt: 2, mb: 2, fontSize: '16px', fontWeight: 400, textTransform: 'capitalize' }}>{userData.organization}</Typography>
                                    <Typography sx={{ mt: 2, mb: 2, fontSize: '16px', fontWeight: 400 }}>{userData.user_name}</Typography>
                                </Box>
                            </Paper>

                        </Grid>

                        <Grid item xs={12} md={8} lg={8}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '86vh',
                                    overflow: 'auto',
                                    border: '0.5px solid #E9EBEE',
                                }}
                            >
                                {isSmallerScreen ? (
                                    // Accordions for smaller screens
                                    <>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>{t('user_page.personal_info')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <PersonalInfo />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>{t('login.password')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ChangePassword />
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ) : (
                                    // Tabs for larger screens
                                    <>
                                        <Box sx={{ backgroundColor: 'white', borderBottom: 1, borderColor: 'divider', mx: 3 }}>
                                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="user tabs">
                                                <Tab label={t('user_page.personal_info')} />
                                                <Tab label={t('login.password')} />
                                            </Tabs>
                                        </Box>

                                        <Box sx={{ p: 3 }}>
                                            {tabValue === 0 && <PersonalInfo />}
                                            {tabValue === 1 && <ChangePassword />}
                                        </Box>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

export default UserProfile;