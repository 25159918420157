import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UserDropdown from './userDropdown';
import styled from '@mui/material/styles/styled';
import LogoHouse from './../../images/logos/logo-house.svg';
import TimerIcon from './../../images/icons/timer_icon.svg';
import CountryAndLanguageSelect from '../login/countryAndLanguageSelect';
import MuiAppBar from '@mui/material/AppBar';
import no_notifications_icon from './../../images/navbar/no_notifications_icon.svg';
import { Box } from '@mui/material';
import axios from 'axios';
import i18next from 'i18next';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottom: '0.5px solid #E9EBEE',
  }));

const Topbar = ({ open, isAuthenticated, setIsAuthenticated }) => {
  const token = localStorage.getItem('token');
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18next.changeLanguage(savedLanguage).then(() => {
      setIsLanguageInitialized(true); // Language has been set
    });
  }, []);

  useEffect(() => {
    // Fetch the message when the language changes
    const handleLanguageChange = () => {
      getMaintenanceMessage(i18next.language);
    };

    i18next.on('languageChanged', handleLanguageChange);
    
    // Initial fetch on component mount
    if (isAuthenticated && isLanguageInitialized) {
      getMaintenanceMessage(i18next.language);
    }
    handleLanguageChange();

    // Cleanup listener on unmount
    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, [isAuthenticated, i18next.language]);

  const getMaintenanceMessage = async (language) => {
    setMaintenanceMessage('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}info/maintenance-message/?language=${language}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
      });
      const maintenanceMessage = response.data;
      setMaintenanceMessage(maintenanceMessage);
    } catch (error) {
      console.error("Error fetching maintenance message:", error);
    }
  };

    return (
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              backgroundColor: 'white',
              color: 'black',
              pr: '24px', // keep right padding when drawer closed
              height: '52px !important',  // override height
              minHeight: '52px !important', // override minHeight
              paddingY: 0
            }}
          >
            <img src={LogoHouse} alt='logo' style={{ height: '32px' }} />
            <Typography
              noWrap
              color="primary"
              variant='h4'
              sx={{ flexGrow: 1, ml: 1, paddingY: 0.5 }}
            >
              Mitigrate
            </Typography>
            {maintenanceMessage && 
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F8F6F4', width: 'auto', padding: 1 }}>
              <img src={TimerIcon} alt='timer-icon' width='15px' height='15px' />
              <Typography sx={{ color: '#6A6C6F', fontSize: '12px', ml: 0.5 }}>{maintenanceMessage}</Typography>
            </Box>
            }
            <CountryAndLanguageSelect fullWidth={false} outlined={false} />
            <IconButton sx={{ padding: 0 }}>
              <img src={no_notifications_icon} alt='notifications-bell' width='25px' height='25px' />
            </IconButton>
            <UserDropdown setIsAuthenticated={setIsAuthenticated} />
          </Toolbar>
        </AppBar>
    );
}

export default Topbar;
