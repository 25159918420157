import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Box, Typography, AccordionSummary, AccordionDetails, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, useTheme } from "@mui/material";
import { Add, Remove } from '@mui/icons-material';
import { getRiskColor } from '../formattingUtils';
import { CustomAccordion } from '../../customStyledComponents';

const RiskSolutionsAccordian = ({ solution, index, sortedRiskSolutions, riskLevels }) => {
    const [expanded, setExpanded] = useState(false);    
    const userLang = i18next.language;
    const { t } = useTranslation();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let solutionImage;
    try {
        solutionImage = require(`../../../images/solutions/${solution.key}.jpg`);
    } catch (e) {
        solutionImage = require(`../../../images/solutions/image_coming_soon.jpg`); // Fallback if no image
    }
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const mapEffectivenessToWords = (efficiencyCategory) => {
        let word, color;

        switch(efficiencyCategory) {
            case "low":
                word = t('effectiveness.moderate');
                color = '#B331C833';
                break;
            case "medium":
                word = t('effectiveness.effective');
                color = '#B331C880';
                break;
            case "high":
                word = t('effectiveness.super');
                color = '#B331C8';
                break;
            default:
                word = t('effectiveness.unknown');
                color = '#000000';
                break;
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    padding: '0px 6px',
                    whiteSpace: 'nowrap',
                    maxWidth: 'fit-content',
                }}
                >
                <Typography
                    sx={{
                    color: color,
                    fontSize: '30px',
                    lineHeight: '1',
                    marginRight: '4px',
                    position: 'relative',
                    top: '-1.5px',
                    }}
                >
                    •
                </Typography>
                <Typography variant="riskTag">{word}</Typography>
            </Box>
        );
    };

    const formatRisks = (risks) => {
        return risks.map((risk, index) => {
            const riskLevel = riskLevels[risk];
            const color = getRiskColor(riskLevel);
            const background = `${color}33`; // 20% opacity
            const border = `1px solid ${color}`;

            return (
                <Box
                    key={index}
                    sx={{
                        display: 'inline-block',
                        borderRadius: '3px',
                        border: border,
                        background: background,
                        padding: '2px 8px',
                        margin: '2px',
                        whiteSpace: 'nowrap',
                        maxWidth: 'fit-content',
                    }}
                >
                    <Typography variant="riskTag">
                        {t(`main_page.${risk}`)}
                    </Typography>
                </Box>
            );
        });
    };

    return(
        <CustomAccordion
        key={solution.key}
        expanded={expanded === solution.key}
        onChange={handleChange(solution.key)}
        index={index}
        array={sortedRiskSolutions}
    >
        <AccordionSummary
            expandIcon={expanded === solution.key ? <Remove color='primary' sx={{ stroke: "#ffffff", strokeWidth: 1 }}/> : <Add color='primary' sx={{ stroke: "#ffffff", strokeWidth: 1 }}/>}
        >
            <Typography sx={{fontSize: '18px', fontWeight: 500, lineHeight: '18px'}}>{solution[`name_${userLang}`]}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{display: "flex", flexDirection: 'column', mt:2.5}}>
                <Box sx={{display: "flex", flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between', alignItems: {xs: 'flex-start', md: 'flex-end'}, gap: 2}}>
                    <Box sx={{display: "flex", flexDirection: 'column', width: {xs: '100%', md: '60%'}}}>
                        <Typography color='primary' sx={{fontSize: '16px', fontWeight: 400, lineHeight: '19.2px'}}>{solution[`short_description_${userLang}`]}</Typography>
                        <Typography sx={{fontSize: '16px', fontWeight: 400, lineHeight: '19.2px', marginTop: 2}}>{solution[`long_description_${userLang}`]}</Typography>
                    </Box>
                    <Box sx={{display: "flex", width: {xs: '100%', md: '35%'}}}>
                        <img src={solutionImage} alt={solution[`name_${userLang}`]} style={{maxWidth: '100%', height: 'auto', borderRadius: '5px'}} />
                    </Box>
                </Box>
                <Box sx={{ my: 7 }}>
                    {!isExtraSmallScreen ? (
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor:'#F9FAFB', borderBottom: '1px solid var(--Gray-200, #E4E7EC);' }}>
                                <TableCell >
                                    <Typography variant="riskTag">
                                    {t('pdf.table_header_solution')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="riskTag">
                                    {`${t('pdf.table_header_units')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="riskTag">
                                    {`${t('pdf.table_header_effectiveness')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="riskTag">
                                        {`${t('pdf.table_header_cost')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="riskTag">
                                    {t('pdf.table_header_coverage')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle3">{solution[`name_${userLang}`]}</Typography>
                                        <Typography variant="h6" color='#475467'>{solution[`short_description_${userLang}`]}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle3">{Math.round(solution.size)} {t(`pdf.unit_translation.${solution.unit_r}`)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle3">{mapEffectivenessToWords(solution.efficiency_category)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle3">{`${solution.cost_range} ${solution.currency}`}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle3">{formatRisks(solution.risks)}</Typography>
                                    </TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                    ) : (
                        <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant='head' sx={{backgroundColor:'#F9FAFB'}}>
                                    <Typography variant="riskTag">
                                    {t('pdf.table_header_solution')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle3">{solution[`name_${userLang}`]}</Typography>
                                    <Typography variant="h6" color='#475467'>{solution[`short_description_${userLang}`]}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' sx={{backgroundColor:'#F9FAFB'}}>
                                    <Typography variant="riskTag">
                                    {`${t('pdf.table_header_units')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle3">{Math.round(solution.size)} {t(`pdf.unit_translation.${solution.unit_r}`)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' sx={{backgroundColor:'#F9FAFB'}}>
                                    <Typography variant="riskTag">
                                    {`${t('pdf.table_header_effectiveness')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle3">{mapEffectivenessToWords(solution.efficiency_category)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' sx={{backgroundColor:'#F9FAFB'}}>
                                    <Typography variant="riskTag">
                                        {`${t('pdf.table_header_cost')}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle3">{`${solution.cost_range} ${solution.currency}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' sx={{backgroundColor:'#F9FAFB'}}>
                                    <Typography variant="riskTag">
                                    {t('pdf.table_header_coverage')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle3">{formatRisks(solution.risks)}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    )}
                    </Box>
                </Box>
            </AccordionDetails>
        </CustomAccordion>
    )
}

export default RiskSolutionsAccordian;