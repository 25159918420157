import { Box, Typography, Tabs, Tab, Divider, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from "@mui/material";
import { useState, useRef, useEffect, useCallback } from "react";
import i18next from "i18next";
import PropTypes from 'prop-types';
import pinkDot from '../../../images/icons/pink_dot.svg';
import water_path_legend from "../../../images/legend/water_path_legend.svg";
import blue_spot_legend from "../../../images/legend/blue_spot_legend.svg";
import plot_boundary_legend from "../../../images/legend/plot_boundary_legend.svg";
import risk_level_0_en from "../../../images/risk_levels/risk_level_0_en.svg";
import risk_level_1_en from "../../../images/risk_levels/risk_level_1_en.svg";
import risk_level_2_en from "../../../images/risk_levels/risk_level_2_en.svg";
import risk_level_3_en from "../../../images/risk_levels/risk_level_3_en.svg";
import risk_level_4_en from "../../../images/risk_levels/risk_level_4_en.svg";
import risk_level_0_no from "../../../images/risk_levels/risk_level_0_no.svg";
import risk_level_1_no from "../../../images/risk_levels/risk_level_1_no.svg";
import risk_level_2_no from "../../../images/risk_levels/risk_level_2_no.svg";
import risk_level_3_no from "../../../images/risk_levels/risk_level_3_no.svg";
import risk_level_4_no from "../../../images/risk_levels/risk_level_4_no.svg";
import risks_icon from "../../../images/icons/risks_icon.svg";
import measures_icon from "../../../images/icons/measures_icon.svg";
import research_icon from "../../../images/icons/research_icon.svg";
import specific_icon from "../../../images/icons/specific_icon.svg";
import surrounding_icon from "../../../images/icons/surrounding_icon.svg";
import maximise_icon from "../../../images/icons/maximise_icon.svg";
import alertIcon from "../../../images/icons/alert_icon.svg";
import { Add, Close, Remove } from '@mui/icons-material';
import RiskLevelChart from "./riskLevelChart";
import SolutionsTable from "./solutionsTable";
import GetPDF from "./getPDF";
import { useTranslation } from "react-i18next";
import RiskSolutionsAccordian from "./riskSolutionsAccordian";
import { ReactComponent as LoaderButton } from '../../../images/logos/logo-house.svg';
import '../../../css/loaders.css'
import { getRiskLevelText } from './../formattingUtils';
import { CustomAccordion } from "../../customStyledComponents";

const OnlineReport = ({ riskSolutions, riskLevels, staticMap, staticMapLoading, precipitation, runoffVolume, viewReport }) => {
    const [tabValue, setTabValue] = useState(0);
    const userLang = i18next.language;
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const [isStickyScrolled, setIsStickyScrolled] = useState(false);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const riskImages = userLang === 'en' ? {
        0: risk_level_0_en,
        1: risk_level_1_en,
        2: risk_level_2_en,
        3: risk_level_3_en,
        4: risk_level_4_en,
    } : {
        0: risk_level_0_no,
        1: risk_level_1_no,
        2: risk_level_2_no,
        3: risk_level_3_no,
        4: risk_level_4_no,
    };
    

    const sectionRefs = {
        0: useRef(null),
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null)
    };

    const riskKeys = Object.keys(riskLevels);

    const getRiskLevel = (risk) => {
        switch (risk) {
            case "very high":
                return 4;
            case "high":
                return 3;
            case "medium":
                return 2;
            case "low":
                return 1;
            case "no risk":
                return 0;
            default:
                return 0;
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        sectionRefs[newValue].current.scrollIntoView({ behavior: "smooth" });
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const sortedRiskSolutions = Object.keys(riskSolutions)
    .map(key => ({ key, ...riskSolutions[key] }))
    .sort((a, b) => b.efficiency - a.efficiency);

    const riskLevelsText = Object.keys(riskLevels).map((key) => (
        <Typography key={key} variant="h6">
            • {getRiskLevelText(riskLevels[key], t)} {t('pdf.alert_for')} {t(`risks.${key}.name`)}
        </Typography>
    ));

    const observerCallback = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const sectionIndex = Object.values(sectionRefs).findIndex(ref => ref.current === entry.target);
                setTabValue(sectionIndex);
            }
        });
    }, [sectionRefs]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        Object.values(sectionRefs).forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            Object.values(sectionRefs).forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [observerCallback, sectionRefs]);

    useEffect(() => {
        const handleScroll = () => {
            const stickyOffset = 85;
            const scrollY = window.scrollY;
            setIsStickyScrolled(scrollY > stickyOffset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box>
            {isMdUp && (
                <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    backgroundColor: 'white',
                    borderBottom: '1px solid #F9FAFB',
                    padding: 0,
                    paddingTop: isStickyScrolled ? '20px' : '0px',
                    minHeight: 'unset',
                    height: 'auto',
                    '& .MuiTabs-root': {
                    minHeight: 'unset',
                    },
                    '& .MuiTab-root': {
                    padding: '0 15px 15px',
                    minHeight: 'unset',
                    },
                }}
                >
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="adaption tabs">
                    <Tab label={t('pdf.contents_page.introduction')} />
                    <Tab label={t('pdf.property_assessment')} />
                    <Tab label={t('main_page.risks')} />
                    <Tab label={t('pdf.solutions')} />
                    <Tab label={t('pdf.contents_page.summary')} />
                </Tabs>
                </Box>
            )}
            <Box sx={{borderLeft: '0.5px solid #E9EBEE', height: '81vh', overflowY: 'scroll'}}>
            <Box ref={sectionRefs[0]} sx={{ display: "flex", justifyContent: "space-between", px: 3, py: 5}}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={pinkDot} height={10} width={10} alt="Pink Dot" />
                    <Typography sx={{ ml: 1 }}>{t('pdf.mitigrate_report')}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <GetPDF viewReport={viewReport}/>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3 }}>
                <Typography variant='h2' sx={{ mb: 2, lineHeight:'30px' }}>{t('pdf.pdf_title')}</Typography>
                <Typography variant="h6" sx={{ width: '60%', color: '#747474', lineHeight: '18.2px' }}>{t('pdf.our_goal')}</Typography>
                <Typography sx={{ my: 7, fontSize: '20px', fontWeight: 400, lineHeight: '22px' }}>{t('pdf.intro_text')}</Typography>
                <Typography variant="reportHeaders" >{t('pdf.report_description_title')}</Typography>
                <Typography variant="h6" sx={{ my: 2 }}>{t('pdf.report_description_text_part_1')}</Typography>
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: 'row', justifyContent: "space-between", mx: 3 }}>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={risks_icon} height={30} width={30} alt="risks_icon" />
                    <Typography variant="h6">{t('pdf.report_description_text_part_2')}</Typography>
                </Box>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={measures_icon} height={30} width={30} alt="measures_icon" />
                    <Typography variant="h6">{t('pdf.report_description_text_part_3')}</Typography>
                </Box>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={research_icon} height={30} width={30} alt="research_icon" />
                    <Typography variant="h6">{t('pdf.report_description_text_part_4')}</Typography>
                </Box>
            </Box>
            <Divider sx={{ bgcolor: '#F1F1F1', marginBottom: 7, marginTop: 10, mx: 3 }}/>
            <Box ref={sectionRefs[1]} sx={{ display: "flex", alignItems: "center", mx: 3, my: 5 }}>
                <img src={pinkDot} height={10} width={10} alt="Pink Dot" />
                <Typography sx={{ ml: 1 }}>{t('pdf.property_assessment')}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3, width:'60%' }}>
                <Typography sx={{ fontSize: '30px', fontWeight: 500, lineHeight: '30px', width:'65%' }}>{t('pdf.risk_section_title')}</Typography>
                <Typography variant="h6" sx={{ mt: 2, mb: 3.5}}>{`${t('pdf.risk_section_text_part_1')}`}<Box component="span" fontWeight={600}>{Math.round(precipitation)}mm</Box> {`${t('pdf.risk_section_text_part_2')} ${t('pdf.risk_section_text_part_3')} ${t('pdf.risk_section_text_part_4')}`}</Typography>
                <Typography variant="reportHeaders" >{t('pdf.image_caption_title')}</Typography>
                <Typography variant="smallInfoText" sx={{ my: 2, width: '70%', lineHeight: '14.4px' }}>{t('pdf.image_caption')}</Typography>
            </Box>
            <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '95%',
                my:6,
                marginLeft: 3
            }}
        >
        {staticMapLoading ? (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80%',
                    width: '100%',
                }}>
                <LoaderButton className="logo-loader" />
            </Box>
            ):(
            <Box>
            <img
                alt="static-map"
                src={staticMap}
                style={{
                    maxWidth: '95%',
                    maxHeight: '95%',
                    objectFit: 'contain',
                    borderRadius: '5px',
                }}
            />
            <Box sx={{ display: "flex", flexDirection: !isMdUp ? "column" : "row", justifyContent: "space-between", maxWidth: '95%' }}>
                <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                    <img src={water_path_legend} alt='water_path_legend' />
                    <Typography variant="smallInfoText" sx={{ my: 2, marginLeft: 1 }}>{t('pdf.stream_arrow_text')}</Typography>
                </Box>
                <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                    <img src={plot_boundary_legend} alt='plot_boundary_legend' />
                    <Typography variant="smallInfoText" sx={{ my: 2, marginLeft: 1 }}>{t('pdf.orange_legend_text')}</Typography>
                </Box>
                <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                    <img src={blue_spot_legend} alt='blue_spot_legend' />
                    <Typography variant="smallInfoText" sx={{ my: 2, marginLeft: 1 }}>{t('pdf.blue_legend_text')}</Typography>
                </Box>
            </Box>
            </Box>
            )}
        </Box>
        <Divider ref={sectionRefs[2]} sx={{ bgcolor: '#EEE9E1', marginBottom: 7, marginTop: 10, mx: 3 }}/>
        <Box sx={{ display: "flex", alignItems: "center", mx: 3, marginBottom: 7, marginTop: 10 }}>
            <img src={risks_icon} height={30} width={30} alt="risks_icon" />
            <Typography sx={{ ml: 1 }}>{t('pdf.property_current_risk')}</Typography>
        </Box>
        <Box sx={{ mx: 3, my: 5 }}>
                {riskKeys.map((key, index) => (
                    <CustomAccordion
                        key={key}
                        expanded={expanded === key}
                        onChange={handleChange(key)}
                        index={index}
                        array={riskKeys}
                    >
                        <AccordionSummary
                            expandIcon={expanded === key ? <Remove color='primary' sx={{ stroke: "#ffffff", strokeWidth: 1 }} /> : <Add color='primary' sx={{ stroke: "#ffffff", strokeWidth: 1 }} />}
                        >
                            <Typography sx={{fontSize: '18px', fontWeight: 500, lineHeight: '18px'}}>{t(`risks.${key}.name`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMdUp ? 'row' : 'column',
                                    alignItems: 'flex-end',
                                    marginTop: 3,
                                    marginBottom: 2,
                                    gap: 5,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: isMdUp ? '55%' : '100%',
                                    }}
                                >
                                    <Typography variant="reportHeaders2">
                                        {`${t('pdf.what_is_the')} ${t(`risks.${key}.name`).toLowerCase()}?`}
                                    </Typography>
                                    <Typography
                                        sx={{ marginTop: 2, marginBottom: 4, width: '100%', fontSize: '20px', fontWeight: 400, lineHeight: '24px' }}
                                    >
                                        {t(`risks.${key}.description`)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="reportHeaders2">
                                            {t('pdf.conclusion')}
                                        </Typography>
                                        <Typography
                                            sx={{ marginTop: 2, fontSize: '14px', fontWeight: 400, lineHeight: '16.8px' }}
                                        >
                                            {t(`risks.${key}.additional_info.risk_level_${getRiskLevel(riskLevels[key])}`, {
                                                precipitationVolume: `${Math.round(precipitation)}mm`,
                                                precipitationVolumeLitres: `${Math.round(runoffVolume)}`,
                                            })}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        width: {xs: '100%', sm: '60%', md: '40%'},
                                        height: 'auto',
                                        marginLeft: isMdUp ? 1 : 0,
                                        objectFit: 'contain',
                                    }}
                                    alt="risk_level"
                                    src={riskImages[getRiskLevel(riskLevels[key])]}
                                />
                            </Box>
                        </AccordionDetails>
                    </CustomAccordion>
                ))}
                <Box
                    sx={{
                        backgroundColor: '#F8F6F4',
                        padding: 1,
                        mt: 6,
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                    >
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        }}
                    >
                        <Box
                        component="img"
                        src={alertIcon}
                        sx={{
                            height: 40,
                            width: 40,
                            mt: 0.5,
                            verticalAlign: 'middle',
                        }}
                        alt="alert_icon"
                        />
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            ml: 1,
                            justifyContent: 'center',
                        }}
                        >
                        <Typography
                            color='primary'
                            sx={{
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '16.8px',
                            }}
                        >
                            {t('main_page.river_flooding')}
                        </Typography>
                        <Typography
                            sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '16.8px',
                            }}
                        >
                            {t('main_page.coming_soon')}
                        </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{ bgcolor: '#EEE9E1', marginBottom: 7, marginTop: 10, mx:3 }}/>
            <Box ref={sectionRefs[3]} sx={{ display: "flex", alignItems: "center", mx: 3, marginBottom: 7, marginTop: 10 }}>
                <img src={measures_icon} height={30} width={30} alt="measures_icon" />
                <Typography sx={{ ml: 1 }}>{t('pdf.proposed_solutions')}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3, marginBottom: 5 }}>
                <Typography sx={{ mb: 2, fontSize: '30px', fontWeight: 500 }}>{t('pdf.measures_section_title')}</Typography>
                <Typography variant="h6" color='#5E6063'>{t('pdf.measures_section_text_part_1')}</Typography>
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: 'row', justifyContent: "space-between", mx: 3 }}>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={specific_icon} height={30} width={30} alt="specific_icon" />
                    <Typography variant="h6">{t('pdf.measures_section_text_part_3')}</Typography>
                </Box>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={surrounding_icon} height={30} width={30} alt="surrounding_icon" />
                    <Typography variant="h6">{t('pdf.measures_section_text_part_4')}</Typography>
                </Box>
                <Box sx={{width: '25%'}}>
                    <Divider sx={{bgcolor:'#000', marginBottom:1}} />
                    <img src={maximise_icon} height={30} width={30} alt="maximise_icon" />
                    <Typography variant="h6">{t('pdf.measures_section_text_part_5')}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3, my: 5 }}>
                <Typography sx={{ marginTop: 5, fontSize: '20px', fontWeight: 500 }}>{t('pdf.solutions_to_reduce_risk')}</Typography>
            </Box>
            {/* Risk Solutions Accordions */}
            <Box>
            {sortedRiskSolutions.map((solution, index) => (
                <Box key={index}>
                    <RiskSolutionsAccordian solution={solution} index={index} sortedRiskSolutions={sortedRiskSolutions} riskLevels={riskLevels} />
                </Box>
            ))}
            </Box>
            <Divider sx={{ bgcolor: '#EEE9E1', marginBottom: 7, marginTop: 10, mx:5 }}/>
            <Box ref={sectionRefs[4]}sx={{ display: "flex", alignItems: "center", mx: 3, marginBottom: 5, marginTop: 10 }}>
                <img src={research_icon} height={30} width={30} alt="research_icon" />
                <Typography sx={{ ml: 1 }}>{t('pdf.conclusion')}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3, marginBottom: 5 }}>
                <Typography sx={{ mb: 2.5, fontSize: '30px', fontWeight: 500 }}>{t('pdf.contents_page.summary')}</Typography>
                <Typography variant="h6" color='#5E6063' sx={{marginBottom: 7.5, width:'70%'}}>{t('pdf.summary_text')}</Typography>
                <Box sx={{ backgroundColor: '#F8F6F4', padding: 3, borderRadius: '10px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 450 }}>{t('pdf.property_current_risk')}</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: isMdUp ? "row" : "column", marginTop: 4, gap: !isMdUp ? 4 : 2 }}>
                        <Box>
                            <Typography variant="h6" sx={{marginBottom: 2}}>{t('pdf.summary_description_part_1')}</Typography>
                            {riskLevelsText}
                            <Typography variant="h6" sx={{marginTop: 2}}>{t('pdf.summary_description_part_2')}</Typography>
                        </Box>
                        {!isExtraSmallScreen && (
                        <Box>
                            <RiskLevelChart riskLevels={riskLevels} />
                        </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={{ backgroundColor: '#F8F6F4', padding: 3, marginTop: 7.5, borderRadius: '10px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 450, marginTop: 1 }}>{t('pdf.adaption_pack')}</Typography>
                    <Box sx={{ display: "inline-flex", justifyContent: "space-between", flexDirection: isMdUp ? "row" : "column", marginTop: 4, gap: isMdUp ? 2 : 4 }}>
                        <Box>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>{t('pdf.adaption_text', { solutionsCount: sortedRiskSolutions.length })}</Typography>
                            {sortedRiskSolutions.map((solution, index) => (
                                <Typography key={solution.key} variant="h6">• {solution[`name_${userLang}`]}</Typography>
                            ))}
                        </Box>
                        <Box>
                            <SolutionsTable sortedRiskSolutions={sortedRiskSolutions} riskLevels={riskLevels} userLang={userLang} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{ bgcolor: '#EEE9E1', marginBottom: 7, marginTop: 10, mx:5 }}/>
            <Box sx={{ display: "flex", flexDirection:'row', justifyContent: 'space-between', alignItems: 'center', mx: 3, mt: 10, mb: 5 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={pinkDot} height={10} width={10} alt="Pink Dot" />
                    <Typography sx={{ ml: 1 }}>{t('pdf.disclaimer')}</Typography>
                </Box>
                <img src={alertIcon} height={40} width={40} alt="alert_icon" />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mx: 3, marginBottom: 5, gap: 3 }}>
                <Box>
                <Typography sx={{fontSize: '14px', fontWeight: 600}}>{t('pdf.liability_risk_assessment')}</Typography>
                <Typography variant="h6">{t('pdf.liability_risk_assessment_text')}</Typography>
                </Box>
                <Box>
                <Typography sx={{fontSize: '14px', fontWeight: 600}}>{t('pdf.liability_measures')}</Typography>
                <Typography variant="h6">{t('pdf.liability_measures_text')}</Typography>
                </Box>
            </Box>
            </Box>
        </Box>
    );
};

OnlineReport.propTypes = {
    riskSolutions: PropTypes.object,
    riskLevels: PropTypes.object
};

export default OnlineReport;
