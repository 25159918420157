import { Checkbox, Accordion, Switch, Tabs, styled } from '@mui/material';

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-root': {
            borderColor: '#F2708E',
        },
      },
    '& .MuiSvgIcon-root': {
        color: 'transparent',
        border: '1px solid #E9EBEE',
        borderRadius: '3px',
        boxShadow: 'none',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            border: 0,
            boxShadow: 'none',
        },
      },
    }));

export const CustomAccordion = styled(Accordion)(({ theme, index, array }) => ({
    marginBottom: '10px',
    boxShadow: 'none', 
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: 0,
    },
    '&.MuiPaper-root': {
        borderRadius: 0,
    },
    '& .MuiAccordionSummary-root': {
        borderRadius: '5px',
        backgroundColor: '#F8F6F4',
        minHeight: '48px', 
        padding: '20px',
        margin: 0,
        width: '100%',
        '&:hover': {
            backgroundColor: '#F2EEE9',
        },
        '&.Mui-expanded': {
            minHeight: '48px',
            margin: 0, 
        },
    },
    '& .MuiAccordionSummary-content': {
        margin: 0, 
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: '20px',
        borderRadius: '5px',
        border: '1px solid #EEE9E1',
        marginTop: '10px',
        marginBottom: '10px',
    },
}));

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const CustomTabs = styled(({ fontSize, ...props }) => <Tabs {...props} />)(({ theme, fontSize }) => ({
  backgroundColor: '#F2F2F2',
  borderRadius: '5px',
  width: '100%',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .MuiTab-root': {
    backgroundColor: '#F2F2F2',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.5, 1),
    color: 'black',
    fontSize: fontSize || '12px',
    fontWeight: 450,
    borderRadius: '5px',
    minHeight: 'unset',
    flex: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.30)',
    },
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: 'white',
    color: 'black',
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1),
    minHeight: 'unset',
  },
  '& .MuiTabs-indicator': { display: 'none' },
}));