import React from "react";
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Link, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const BreadCrumbs = ({ pageNames }) => {
    const { t} = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Breadcrumbs sx={{ ml: 3 }} separator="›">
                <Link underline="hover" fontSize="12px" color="inherit" href="/prevent">
                    <HomeIcon sx={{ verticalAlign: 'middle', mr: 1 }} fontSize="inherit" color="primary" />
                    <Typography variant="bodyTiny">{t('main_page.home')}</Typography>
                </Link>

                {pageNames.map((pageName, index) => (
                    <Link
                        underline="hover"
                        color={index === pageNames.length - 1 ? '#39393D' : 'inherit'}
                        href={pageName.route}
                        key={index}
                    >
                        <Typography 
                        variant="bodyTiny" 
                        fontWeight={index === pageNames.length - 1 ? 500 : 'normal'}
                        >
                            {t(pageName.name)}
                        </Typography>
                    </Link>
                ))}

            </Breadcrumbs>
            { !isSmallerScreen && (
            <Box sx={{ mr: 3 }}>
                <Typography variant="bodyTiny">{t('adaptions_page.where_info_coming_from')}</Typography>
                <Link fontSize="10px" color="primary" href="/prevent">{t('adaptions_page.learn_more')}</Link>
            </Box>
            )}
        </Box>
    );
};

export default BreadCrumbs;
