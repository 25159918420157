import React from 'react';
import { Box, Typography, FormControlLabel, useMediaQuery, useTheme } from '@mui/material';
import { useSearchContext } from '../../../SearchContext';
import { useTranslation } from 'react-i18next';
import { CustomCheckbox } from '../../customStyledComponents';

const PropertyTickBoxes = ({ validationErrors }) => {
    const { hasBasement, setHasBasement, isGroundFloor, setIsGroundFloor, experiencedSewageBackup, setExperiencedSewageBackup } = useSearchContext();
    const theme = useTheme();
    const { t } = useTranslation();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleCheckboxChange = (setStateFunction, key) => {
        setStateFunction({
            yes: key === 'yes',
            no: key === 'no',
            idk: key === 'idk',
        });
    };

    return (
        <Box>
            {/* Basement Section */}
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    animation: validationErrors.hasBasement ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.hasBasement ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="bodyRegular" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                    {t('main_page.does_property_have_basement')}
                </Typography>
                {validationErrors.hasBasement && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={hasBasement.yes} onChange={() => handleCheckboxChange(setHasBasement, 'yes')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={hasBasement.no} onChange={() => handleCheckboxChange(setHasBasement, 'no')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={hasBasement.idk} onChange={() => handleCheckboxChange(setHasBasement, 'idk')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>

            {/* Ground Floor Section */}
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mt: isSmallerScreen ? 0 : 1,
                    animation: validationErrors.isGroundFloor ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.isGroundFloor ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="bodyRegular" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                {t('main_page.is_property_ground_floor')}
                </Typography>
                {validationErrors.isGroundFloor && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={isGroundFloor.yes} onChange={() => handleCheckboxChange(setIsGroundFloor, 'yes')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={isGroundFloor.no} onChange={() => handleCheckboxChange(setIsGroundFloor, 'no')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={isGroundFloor.idk} onChange={() => handleCheckboxChange(setIsGroundFloor, 'idk')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>

            {/* Sewage Backup Section */}
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mt: isSmallerScreen ? 0 : 1,
                    animation: validationErrors.experiencedSewageBackup ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.experiencedSewageBackup ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="bodyRegular" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                {t('main_page.experienced_sewage_backup')}
                </Typography>
                {validationErrors.experiencedSewageBackup && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mb: isSmallerScreen ? 0 : 1 }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={experiencedSewageBackup.yes} onChange={() => handleCheckboxChange(setExperiencedSewageBackup, 'yes')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={experiencedSewageBackup.no} onChange={() => handleCheckboxChange(setExperiencedSewageBackup, 'no')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={experiencedSewageBackup.idk} onChange={() => handleCheckboxChange(setExperiencedSewageBackup, 'idk')} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>
        </Box>
    );
}

export default PropertyTickBoxes;
