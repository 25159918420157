import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';
import { useSearchContext } from '../../SearchContext';
import { useNavigate } from "react-router-dom";
import selected_icon from "../../images/map_markers/selected_icon.svg";
import not_selected_icon from "../../images/map_markers/not_selected_icon.svg";
import MapLegend from "./mapLegend";
import ReturnPeriodFilter from "./returnPeriodFilter";
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { FullscreenControl, MapTypeControl } from "./customMapControls";
import { ReactComponent as LoaderButton } from '../../images/logos/logo-house.svg';
import { Box } from '@mui/material';

const PropertyMap = ({ google }) => {
    const { setAffordableSolutions, experiencedSewageBackup, isGroundFloor, setSelectedIndex, setIsLoading, propertyData, setPropertyData, allBuilding, setAllBuilding, selectedBuilding, setSelectedBuilding, setSearchByAddress, returnPeriod, riskYear, radius, setBlueSpotsData, setStreamsData, hasBasement} = useSearchContext();
    const [mapKey, setMapKey] = useState(0);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [exteriorPoints, setExteriorPoints] = useState([])
    const [interiorPoints, setInteriorPoints] = useState([])
    const [streams, setStreams] = useState([])
    const [mapType, setMapType] = useState('roadmap')
    const isSmallerScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const [mapInstance, setMapInstance] = useState(null);
    const [mapTypeText, setMapTypeText] = useState('Satellite');

    useEffect(() => {
        // set the map key to a new value to force the map to re-render with new data.
        setMapKey(mapKey + 1);
        setSelectedIndex('all_adaptations')
        getPlotBuildings()
        getBlueSpots()
        getStreams()
    }, [propertyData]);


    const getPlotBuildings = async () => {
        if (propertyData.plot_id) {
        const plotBuildingsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/same-plot/${propertyData.plot_id}/${returnPeriod}/${riskYear}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const plotBuildings = plotBuildingsResponse.data;
        setAllBuilding(plotBuildings.buildings);
    }
    }

    const getBlueSpots = async () => {
        try{        
            if (propertyData.lat && propertyData.lon) {
            const blueSpotsData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}bluespots/coordinates/${propertyData.lat},${propertyData.lon}/${propertyData.country}/${radius}/${returnPeriod}/${riskYear}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const blueSpots = blueSpotsData.data;
            setBlueSpotsData(blueSpots)
            setExteriorPoints(blueSpots.geom_exterior.map(polygon => (
            polygon.coordinates[0].map(([lat, lng]) => ({ lat, lng }))
            )))
            setInteriorPoints(blueSpots.geom_interior.flatMap(multi_polygons => (
            multi_polygons.coordinates.map((polygon) => (
                polygon[0].map(([lat, lng]) => ({ lat, lng }))
            )
            ))))
            }
        } catch (error) {
            console.error("Error fetching blue spots:", error);
        }
    }

    const getStreams = async () => {
        if (propertyData.lat && propertyData.lon) {
        const streamsData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}streams/coordinates/${propertyData.lat},${propertyData.lon}/${propertyData.country}/${radius}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const streams = streamsData.data;
        setStreams(streams.geom)
        setStreamsData(streams)
    }
    }

    const OnClickHandle = (building) => {
        if (building.building_id != selectedBuilding) {
        setAffordableSolutions([])
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_BASE_URL}building-info/building-id/filtered/${building.building_id}/${returnPeriod}/${riskYear}/?has_basement=${!hasBasement.no}`,{ 
            method: 'GET', 
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
                
            }})
            .then((response) => response.json())
            .then((data) => {
                setPropertyData(data)
                setSelectedBuilding(data.building_id)
                setIsLoading(false)
                navigate(`/prevent/adaptions/${data.building_id}?risk_year=${riskYear}&return_period=${returnPeriod}&has_basement=${Object.keys(hasBasement).find(key => hasBasement[key])}&is_ground_floor=${Object.keys(isGroundFloor).find(key => isGroundFloor[key])}&experienced_sewage_backup=${Object.keys(experiencedSewageBackup).find(key => experiencedSewageBackup[key])}`);
            })
            .catch((error) => {
                setIsLoading(false) 
                console.error("Error:", error);
            });
        }
    };

    function GetMarkerColor(building) {
        if (building.building_id == selectedBuilding) { 
        return selected_icon 
        }else{ 
        return not_selected_icon 
        }
    }

    const addMapTypeListener = (mapProps, map) => {
        const listener = () => {
        setMapType(map.getMapTypeId());
        };

        map.addListener('maptypeid_changed', listener);

        // Cleanup listener on unmount
        return () => {
        google.maps.event.removeListener(listener);
        };
    };

    const onMapReady = (mapProps, map) => {
        setMapInstance(map);
        map.setOptions({
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
        });
        addMapTypeListener(mapProps, map);
    };

    useEffect(() => {
        const updateMarkers = (markersData, markerType, iconGetter, clickHandler) => {
            if (mapInstance && google && markersData.length > 0) {
            // Clear existing markers
            if (mapInstance[markerType]) {
                mapInstance[markerType].forEach(marker => marker.map = null);
            }
            mapInstance[markerType] = [];
        
            // Create and add new markers
            markersData.forEach((data) => {
                const iconElement = document.createElement('img');
                iconElement.src = iconGetter(data)
                iconElement.style.width = '35px';
                iconElement.style.height = '35px';

                // Add data-testid or other test attribute for Cypress
                iconElement.setAttribute('data-testid', `building-marker-${data.building_id}`);

                const marker = new google.maps.marker.AdvancedMarkerElement({
                position: {
                    lat: data.lat,
                    lng: data.lon,
                },
                map: mapInstance,
                content: iconElement, // Use content instead of icon
                });
        
                // Add click event to marker if a click handler is provided
                if (clickHandler) {
                marker.addListener('click', () => clickHandler(data));
                }
        
                // Store marker reference for cleanup
                mapInstance[markerType].push(marker);
            });
            }
        };
        
        // Update building markers
        updateMarkers(allBuilding, 'advancedBuildingMarkers', GetMarkerColor, OnClickHandle);
    
    }, [allBuilding, mapInstance, google, selectedBuilding]);    

    return(
        <>
        <Map
            key={mapKey}
            google={google}
            zoom={17}
            initialCenter={{
            lat: propertyData.lat,
            lng: propertyData.lon,
            }}
            cursor={'pointer'}
            mapTypeControl={false}
            fullscreenControl={false}
            zoomControl={false}
            streetViewControl={false}
            onReady={onMapReady}
        >

        <FullscreenControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} />

        <MapTypeControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} mapTypeText={mapTypeText} setMapTypeText={setMapTypeText}/>

        <ReturnPeriodFilter />

        <MapLegend />

            <Polygon
            paths={propertyData.geom_plot.coordinates[0].map(([lat, lng]) => ({ lat, lng }))}
            strokeColor="#FF0000"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="#FFA500"
            fillOpacity={0.35}
            />

            {streams.map((polyline, index) => (
            <Polyline
                key={index}
                path={polyline}
                strokeColor="#0000FF"
                strokeOpacity={0.8}
                strokeWeight={1.4}
                icons={[{
                'icon': { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2.2 },
                'offset': '100%',
                'repeat': '100px',
                }]}
            />
            ))}

            <Polygon
            paths={exteriorPoints.concat(interiorPoints)}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={0}
            fillColor="#0000FF"
            fillOpacity={0.35}
            clickable={false}
            />

        </Map>
        </>
    )

    };

    const LoadingContainer = () => (
        <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
            width: '100%',
        }}
        >
        <LoaderButton className="logo-loader" />
        </Box>
    )

    export default GoogleApiWrapper({
        apiKey: process.env.REACT_APP_GOOGLE_API_TOKEN,
        LoadingContainer: LoadingContainer,
        libraries: ['marker']
    })(PropertyMap);