import { createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const theme = createTheme({
    palette: {
        primary: {
            main: '#EC325D',
            contrastText: "#fff"
        },
        secondary: {
            main: '#DEDEDE',
        },
        info: {
            main: '#8A8A8A',
        },
        success: {
            main: '#F8DEE2',
        },
        error: {
            main: '#D3D6DB',
        },
        warning: {
            main: '#39393D',
        },
        },
        components: {
        MuiButtonBase: {
            defaultProps: {
            disableRipple: true,
            },
            styleOverrides: {
            root: {
                boxShadow: 'none', // Remove shadow from MuiButtonBase
            },
            },
        },
        MuiButton: {
            defaultProps: {
            disableRipple: true,
            },
            styleOverrides: {
            root: {
                boxShadow: 'none', // Remove shadow from MuiButton
                '&:hover': {
                boxShadow: 'none', // Ensure no shadow on hover
                },
                '&:active': {
                boxShadow: 'none', // Ensure no shadow on active state
                },
            },
            },
        },
        MuiIconButton: {
            styleOverrides: {
            root: {
                '&:hover': {
                backgroundColor: 'transparent',
                },
            },
            },
        },
        MuiSelect: {
            defaultProps: {
            IconComponent: KeyboardArrowDownIcon,
            },
            styleOverrides: {
            root: {
                borderRadius: '5px',
                border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                '&:hover': {
                borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                },
                '&.Mui-focused': {
                borderColor: 'var(--Primary-Colors-Color-3, #EC325D) !important',
                borderWidth: '1px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                },
            },
            icon: {
                fontSize: '1.15rem',
            },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
            root: {
                backgroundColor: 'transparent',
                '&:hover': {
                backgroundColor: 'transparent !important',
                boxShadow: 'none',
                },
                '&.Mui-selected': {
                backgroundColor: 'transparent !important',
                boxShadow: 'none',
                },
            },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
            root: {
                fontSize: '10px',
                color: '#6A6C6F',
            },
            },
        },
        MuiTextField: {
            defaultProps: {
            margin: 'none',
            color: 'primary',
            fullWidth: true,
            },
            styleOverrides: {
            root: {
                '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                '& fieldset': {
                    borderRadius: '5px',
                    border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--Primary-Colors-Color-3, #EC325D) !important',
                    borderWidth: '1px',
                },
                '& input::placeholder': {
                    color: '#39393D',
                    opacity: 1,
                },
                },
            },
            },
        },
        MuiMenu: {
            styleOverrides: {
            paper: {
                boxShadow: 'none',
                border: '1px solid #ccc',
            },
            },
        },
        MuiPaper: {
            styleOverrides: {
            root: {
                boxShadow: 'none', // Remove shadow from all Paper elements
            },
            },
        },
        MuiCard: {
            styleOverrides: {
            root: {
                boxShadow: 'none', // Remove shadow from all Card elements
            },
            },
        },
        MuiAppBar: {
            styleOverrides: {
            root: {
                boxShadow: 'none', // Remove shadow from all AppBar elements
            },
            },
        },
        MuiDialog: {
            styleOverrides: {
            paper: {
                boxShadow: 'none', // Remove shadow from all Dialog elements
            },
            },
        },
        },
        typography: {
        button: {
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 500
        },
        fontFamily: "neue-haas-grotesk-display",
        h1: {
            fontSize: '80px',
            lineHeight: '70px',
            fontWeight: 450
        },
        h2: {
            fontSize: '30px',
            lineHeight: '28px',
            fontWeight: 450
        },
        h3: {
            fontSize: '25px',
            lineHeight: '24px',
            fontWeight: 450
        },
        h4: {
            fontSize: '22px',
            lineHeight: '22px',
            fontWeight: 450
        },
        h5: {
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 450
        },
        h6: {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: 450
        },
        bodyLarge: {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: 450
        },
        bodyRegular: {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 450
        },
        bodySmall: {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 450
        },
        bodyTiny: {
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 450
        },
    },
});

export default theme;