import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useSearchContext } from '../../SearchContext';
import i18next from 'i18next';
import EnIcon from '../../images/flags/en-icon.png';
import NoIcon from '../../images/flags/no-icon.png';
import { useNavigate } from 'react-router-dom';

const options = [
    { label: 'Norway (English)', searchCountry: 'norway', userLanguage: 'en', icon: NoIcon },
    { label: 'Norge (Norsk)', searchCountry: 'norway', userLanguage: 'no', icon: NoIcon },
    { label: 'UK (English)', searchCountry: 'uk', userLanguage: 'en', icon: EnIcon },
];

const CountryAndLanguageSelect = ({ fullWidth, outlined }) => {
    const { searchCountry, setSearchCountry, countryMenuOpen, setCountryMenuOpen, setPropertyData, setSelectedBuilding, setAllBuilding } = useSearchContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const shouldUseSmallerStyles = isSmallerScreen && !outlined;

    const userLanguage = localStorage.getItem('language') || 'en';

    const currentValue = `${searchCountry}_${userLanguage}`;

    const handleClose = () => setCountryMenuOpen(false);

    const handleChange = (event) => {
        setPropertyData([]);
        setSelectedBuilding('');
        setAllBuilding([]);
        localStorage.removeItem('previousSearchlat');
        localStorage.removeItem('previousSearchlng');
        const [newSearchCountry, newUserLanguage] = event.target.value.split('_');
        localStorage.setItem('searchCountry', newSearchCountry)
        setSearchCountry(newSearchCountry);
        i18next.changeLanguage(newUserLanguage);
        localStorage.setItem('language', newUserLanguage);
        navigate(`/prevent`);
    };

    return (
        <Box>
            {outlined && (
                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>
                    {t('login.country_and_language')}
                </InputLabel>
            )}
            <Select
                {...(fullWidth && { fullWidth })}
                variant="outlined"
                label="language"
                value={currentValue}
                open={countryMenuOpen}
                onClose={handleClose}
                onOpen={() => setCountryMenuOpen(true)}
                onChange={handleChange}
                sx={{
                    borderRadius: '5px',
                    border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    display: "flex",
                    flexDirection: 'row',
                    '& legend': { display: 'none' },
                    '&:hover': {
                        borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                    },
                    '&.Mui-focused': {
                        borderColor: `var(--Primary-Colors-Color-3, ${theme.palette.primary.main}) !important`,
                        borderWidth: '1px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    ...(!outlined && {
                        border: 0,
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        { border: 0, },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: 0, },
                        '&.Mui-focused': {
                            border: 0,
                        },
                    }),
                }}
            >
                {options.map(({ label, searchCountry, userLanguage, icon }) => (
                    <MenuItem
                        key={`${searchCountry}_${userLanguage}`}
                        value={`${searchCountry}_${userLanguage}`}
                        sx={{
                            justifyContent: shouldUseSmallerStyles ? 'center' : 'flex-start',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent !important',
                                boxShadow: 'none',
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'transparent !important',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <img src={icon} width={20} height={20} alt={label} />
                            {!shouldUseSmallerStyles && (
                                <Typography sx={{ ml: 1 }}>{label}</Typography>
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default CountryAndLanguageSelect;

