import React from 'react';
import { Box, Button, Popover, InputLabel, FormControl, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const FormCalender = ({ label, dateValue, onDateChange, open, anchorEl, onOpen, onClose, installationDate }) => {
    const { t } = useTranslation();
    const today = dayjs();
    const theme = useTheme();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <Box sx={{ my: 2 }}>
                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>
                    {label}
                </InputLabel>
                <FormControl fullWidth>
                    <Button
                        variant="outlined"
                        onClick={onOpen}
                        fullWidth
                        sx={{
                            fontSize: '14px',
                            fontWeight: 300,
                            color: '#39393D',
                            display: 'flex',
                            padding: '10px 15px',
                            justifyContent: 'space-between',
                            borderRadius: '5px',
                            border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                            },
                            '&.Mui-focused': {
                                borderColor: `var(--Primary-Colors-Color-3, ${theme.palette.primary.main}) !important`,
                                borderWidth: '1px',
                            },
                        }}
                    >
                        {dateValue 
                        ? dayjs(dateValue).format('DD/MM/YYYY') 
                        : label === t('adaptions_page.installation_date') 
                            ? t('adaptions_page.select_installation_date') 
                            : t('adaptions_page.select_last_maintenance')}
                    </Button>

                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={onClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <DateCalendar
                            value={dateValue}
                            onChange={onDateChange}
                            minDate={label === t('adaptions_page.last_maintenance') && installationDate ? dayjs(installationDate) : null}
                            maxDate={today}
                        />
                    </Popover>
                </FormControl>
            </Box>
        </LocalizationProvider>
    );
};

export default FormCalender;
